<template>
  <div>
    <b-form-checkbox v-model="status" name="assignment-checkbox">
      {{ message }}
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: 'AssignmentEmailCheckbox',
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      status: false
    };
  },
  watch: {
    status () {
      this.$emit('sendEmail', this.status);
    }
  }
};
</script>

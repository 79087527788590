<template>
  <div>
    <div class="container-fluid">
      <!-- Sidebar -->
      <div class="row">
        <div
          class="col-9 col-sm-6 col-md-5 col-lg-3 col-xl-2 border-right pt-4 bg-white sidebar sidebar-multigroups"
          :class="{'d-none': !showClassesForSmall, 'd-lg-block': !showClassesForSmall}">
          <h4 class="text-capitalize d-none d-lg-block">
            {{ accountTypeText.admin }} Tools
          </h4>
          <div class="d-lg-none float-right">
            <font-awesome-icon
              class="text-muted pointer"
              icon="times"
              @click="showClassesForSmall = !showClassesForSmall" />
          </div>
          <div class="text-uppercase text-muted">
            <small>{{ accountTypeText.plural }}</small>
          </div>
          <div class="nav flex-column">
            <li v-for="(multigroup, $multigroupIndex) in multigroups" :key="$multigroupIndex" class="nav-item">
              <small>
                <router-link
                  v-if="(!multigroup.perms_count || (multigroup.perms_count && multigroup.perms_count > 15))"
                  :to="accountTypeText.path + multigroup.id + '/users'"
                  class="nav-link pr-0"
                  :class="[{ active: isCurrentClass(multigroup.id) }]">
                  {{ multigroup.name }}
                </router-link>
              </small>
            </li>
          </div>
          <div class="text-uppercase text-muted">
            <small>Other Tools</small>
          </div>
          <div class="nav flex-column mb-4">
            <li v-if="isEnterprise" class="nav-item pr-0">
              <small>
                <router-link
                  :to="accountTypeText.path + 'all/users'"
                  class="nav-link pr-0"
                  :class="[{ active: showAllUsers === true }]">
                  All {{ accountTypeText.memberType1 }}
                </router-link>
                <span v-if="showAllUsers">({{ aliasLimit - aliasCount }} slots available)</span>
              </small>
            </li>
            <li class="nav-item pr-0">
              <small><a class="nav-link" href="javascript:void(0)" @click="openDocuments()">Documents</a></small>
            </li>
          </div>
        </div>
        <!-- Main Container-->
        <div class="col-12 offset-lg-2 offset-lg-3 col-lg-9 offset-xl-2 col-xl-10 mt-4">
          <sp-loader v-if="loading" @loadingEmit="modalTest()" />
          <div v-if="!loading">
            <div class="row mx-lg-0 mb-3">
              <div class="col-12 col-sm-6 d-none d-lg-block">
                <h3>{{ selectedMultigroup.name }}</h3>
              </div>
              <div class="col-12 col-sm-6 d-lg-none">
                <h3 class="d-inline mr-2">
                  {{ selectedMultigroup.name }}
                </h3>
                <a href="javascript:void(0)" class="p-0 btn btn-link"
                   @click="showClassesForSmall = !showClassesForSmall">
                  <small>Change <span class="text-lowercase">{{ accountTypeText.singular }}</span></small>
                </a>
              </div> <!-- Buttons for large and XL -->
              <div class="col-12 col-sm-6">
                <div
                  v-if="!showAllUsers"
                  class="float-right">
                  <b-button
                    class="text-capitalize my-1"
                    variant="outline-dark"
                    size="sm"
                    @click="addExistingUser()">
                    <font-awesome-icon icon="user-plus" />
                    <small><span class="d-none d-md-inline ml-1">Add </span>Existing {{ accountTypeText.memberType3 }}
                    </small>
                  </b-button>
                </div>
                <div
                  v-if="user && (isEnterprise || user.organization.role === 'master_admin')"
                  class="float-right">
                  <b-button
                    class="text-capitalize my-1 mr-2"
                    variant="outline-dark"
                    size="sm"
                    @click="editDetails({})">
                    <font-awesome-icon icon="user-plus" />
                    <small><span class="d-none d-md-inline ml-1">Create </span>New {{ accountTypeText.memberType3 }}</small>
                  </b-button>
                </div>
              </div>
            </div>
            <div class="row mx-lg-0">
              <div class="col-12">
                <b-button
                  v-if="!showAllUsers"
                  class="pl-0"
                  variant="link"
                  @click="checkAll()">
                  <small>{{ selectAllText }}</small>
                </b-button>
                <span v-if="usersMultigroup && organizationUsers && showAllUsers" class="mr-3">
                  <small>Showing {{ aliasCount }} of {{ aliasLimit }} total {{ accountTypeText.memberType1 }}. {{ aliasLimit - aliasCount }} slots available </small>
                </span>
                <span v-if="usersMultigroup && organizationUsers && !showAllUsers" class="mr-3">
                  <small>Showing {{ usersMultigroup.length }} of {{ organizationUsers.length }} {{ accountTypeText.memberType2 }}</small>
                </span>
                <span>
                  <b-button
                    v-if="!showAllUsers"
                    type="button"
                    :disabled="checkboxesSelected.length === 0"
                    variant="outline-dark"
                    size="sm"
                    class="m-1 text-capitalize"
                    @click="showConfirmationModal('remove')">
                    <font-awesome-icon icon="user-times" /> <small class="ml-1">Remove from {{ accountTypeText.singular }}</small>
                  </b-button>
                  <b-button
                    v-if="showDeleteButton"
                    :disabled="checkboxesSelected.length === 0"
                    variant="outline-dark"
                    size="sm"
                    class="m-1 text-capitalize"
                    @click="showConfirmationModal('delete')">
                    <font-awesome-icon icon="trash" /> <small class="ml-1">Delete from Account</small>
                  </b-button>
                </span>
              </div>
            </div>
            <!-- Main table -->
            <div class="row mx-lg-0">
              <div class="col-12 my-3">
                <div v-if="usersMultigroup.length > 0" class="card classes-block">
                  <div class="card-body" style="padding: 0px 15px;">
                    <div class="row header px-0">
                      <div class="d-lg-block border-right" style="width:40px;" />
                      <div class="col-10 col-sm-7 col-lg-3 border-right-sm pt-2 pb-2 hover-pointer" @click="sortColumn('last_name')">
                        <span class="hover-pointer d-block">Name
                          <font-awesome-icon v-if="sortMethod['type'] === 'last_name' && sortMethod['sortDirection'] === 'desc'" icon="caret-down" />
                          <font-awesome-icon v-else-if="sortMethod['type'] === 'last_name' && sortMethod['sortDirection'] === 'asc'" icon="caret-up" />
                          <font-awesome-icon v-else icon="sort" />
                        </span>
                      </div>
                      <div v-if="!showAllUsers" class="d-none d-sm-block col-4 col-lg-2 border-right-lg pt-2 pb-2 pr-0 hover-pointer" @click="sortColumn('percent_complete')">
                        <span class="d-block">Progress
                          <font-awesome-icon v-if="sortMethod['type'] === 'percent_complete' && sortMethod['sortDirection'] === 'desc'" icon="caret-down" />
                          <font-awesome-icon v-else-if="sortMethod['type'] === 'percent_complete' && sortMethod['sortDirection'] === 'asc'" icon="caret-up" />
                          <font-awesome-icon v-else icon="sort" />
                        </span>
                      </div>
                      <div class="col-2 col-lg-2 col-xl-1 d-none d-lg-block border-right pt-2 pb-2 pr-0 hover-pointer" @click="sortColumn('total_lesson_views')">
                        <span class="d-none d-sm-inline d-block">Views
                          <font-awesome-icon v-if="sortMethod['type'] === 'total_lesson_views' && sortMethod['sortDirection'] === 'desc'" icon="caret-down" />
                          <font-awesome-icon v-else-if="sortMethod['type'] === 'total_lesson_views' && sortMethod['sortDirection'] === 'asc'" icon="caret-up" />
                          <font-awesome-icon v-else icon="sort" />
                        </span>
                      </div>
                      <div class="col-2 col-lg-2 col-xl-3 d-none d-lg-block border-right-lg pt-2 pb-2 pr-0 hover-pointer" :class="{'d-sm-block col-4': (showAllUsers == true)}" @click="sortColumn('last_access')">
                        <span class="d-none d-sm-inline d-block">Last Access
                          <font-awesome-icon v-if="sortMethod['type'] === 'last_access' && sortMethod['sortDirection'] === 'desc'" icon="caret-up" />
                          <font-awesome-icon v-else-if="sortMethod['type'] === 'last_access' && sortMethod['sortDirection'] === 'asc'" icon="caret-down" />
                          <font-awesome-icon v-else icon="sort" />
                        </span>
                      </div>
                      <div v-if="showAllUsers" class="col-2 d-none d-lg-block border-right pt-2 pb-2 hover-pointer" @click="sortColumn('multigroups')">
                        <span class="d-none d-sm-inline d-block">Group
                          <font-awesome-icon v-if="sortMethod['type'] === 'multigroups' && sortMethod['sortDirection'] === 'desc'" icon="caret-down" />
                          <font-awesome-icon v-else-if="sortMethod['type'] === 'multigroups' && sortMethod['sortDirection'] === 'asc'" icon="caret-up" />
                          <font-awesome-icon v-else icon="sort" />
                        </span>
                      </div>
                      <div class="col-2 d-none d-lg-block pt-2 pb-2 hover-pointer" @click="sortColumn('multigroup_perms')">
                        <span class="d-none d-sm-inline d-block">Type
                          <font-awesome-icon v-if="sortMethod['type'] === 'multigroup_perms' && sortMethod['sortDirection'] === 'desc'" icon="caret-down" />
                          <font-awesome-icon v-else-if="sortMethod['type'] === 'multigroup_perms' && sortMethod['sortDirection'] === 'asc'" icon="caret-up" />
                          <font-awesome-icon v-else icon="sort" />
                        </span>
                      </div>
                    </div>
                    <div v-for="(student, studentIndex) in usersMultigroup" :key="studentIndex">
                      <div class="row border-top">
                        <div class="py-2 border-right text-center" style="width:40px">
                          <!-- Dynamically create the id and value so they will be easy to retrieve (idcustomer + index number) -->
                          <b-form-checkbox
                            v-if="student.idcustomer !== user.id"
                            :id="student.aliasid.toString()"
                            v-model="checkboxesSelected"
                            style="margin-left:11px;"
                            :value="student.aliasid.toString()" />
                        </div>
                        <div class="col-10 col-sm-7 col-lg-3 py-2 border-right-sm">
                          <b-link
                            @click="loadMultigroupPermissions(student.idcustomer)">
                            {{ formatDisplayName(student) }}
                          </b-link>
                        </div>
                        <div v-if="!showAllUsers" class="d-none d-sm-block col-4 col-lg-2 py-2 border-right-lg">
                          <b-link
                            @click="stopAccordion($event); openUserDetails(student.aliasid);">
                            {{ formatProgress(student) }}
                          </b-link>
                        </div>
                        <div class="col-2 col-lg-2 col-xl-1 d-none d-lg-block py-2 border-right">
                          <b-link
                            @click="stopAccordion($event); openUserDetails(student.aliasid);">
                            {{ student.total_lesson_views || 0 | striphtml }}
                          </b-link>
                        </div>
                        <div class="col-2 d-none col-lg-2 col-xl-3 d-lg-block py-2 border-right-lg"
                             :class="{ 'd-sm-block col-4': showAllUsers === true }">
                          {{ formatDateTime(student.last_access) }}
                        </div>
                        <div v-if="showAllUsers" class="col-2 d-none d-lg-block py-2 border-right">
                          {{ student.multigroups.length ? "in " + groupCount(student) : "no " + accountTypeText.singular }}
                        </div>
                        <div class="col-2 d-none d-lg-block py-2 text-truncate">
                          {{ student.multigroup_perms >= 1 ? accountTypeText.admin : accountTypeText.memberType3 }}
                        </div>
                      </div>
                      <b-collapse
                        :id="'accordion' + student.idcustomer"
                        v-model="activeAccordions['accordion' + student.idcustomer]"
                        :class="'row pt-2 px-0 border-top'">
                        <div class="col-12 mt-4">
                          <div class="row pl-lg-4">
                            <div class="col-12 col-lg-5">
                              <div class="row">
                                <h4 class="col-12 col-md-4 text-nowrap font-weight-bold">
                                  Details
                                </h4>
                                <span class="align-middle">
                                  <a href="javascript:void(0)" class="small pl-3" @click.prevent="editDetails(student)">
                                    Edit Details
                                  </a>
                                </span>
                              </div>
                              <div v-for="(attribute, $attributeIndex) in student_attributes" :key="$attributeIndex">
                                <div class="row mb-3">
                                  <div class="col-12 col-md-4 text-nowrap">
                                    <p v-if=" attribute['value'] === 'Group Name: ' " class="font-weight-bold m-0">
                                      {{ accountTypeText.singular }} Name:
                                    </p>
                                    <p v-else class="font-weight-bold m-0">
                                      {{ attribute['value'] | striphtml }}
                                    </p>
                                  </div>
                                  <div class="col-12 col-md-8 m-0">
                                    <p
                                      v-if=" attribute['value'] === 'Created At: ' || attribute['value'] === 'Last Access: ' "
                                      class="text-left m-0">
                                      {{ formatDateTime( student[attribute["key"]] ) }}
                                    </p>
                                    <p v-else class="text-left m-0">
                                      {{ student[attribute['key']] | striphtml }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-lg-6">
                              <div v-if="!showAllUsers">
                                <div class="row">
                                  <h4 class="font-weight-bold pl-3 pr-3">
                                    Permissions
                                  </h4>
                                  <span class="align-middle">
                                    <div v-if="permissions[student['idcustomer']] && !isCurrentUser(student.idcustomer)">
                                      <a
                                        v-if="calculateSelectAllValue( student.idcustomer )"
                                        href="javascript:void(0)" class="small pl-3"
                                        @click.prevent="selectAllPermissions(student)">Deselect All</a>
                                      <a
                                        v-else href="javascript:void(0)"
                                        class="small pl-3"
                                        @click.prevent="selectAllPermissions(student)">Select All</a>
                                    </div>
                                  </span>
                                </div>
                                <div v-if="permissions[student['idcustomer']]">
                                  <table class="table table-borderless table-sm table-responsive">
                                    <thead>
                                      <tr>
                                        <th scope="col">
                                          Type
                                        </th>
                                        <th scope="col" class="text-center">
                                          View
                                        </th>
                                        <th scope="col" class="text-center">
                                          Create
                                        </th>
                                        <th scope="col" class="text-center">
                                          Modify
                                        </th>
                                        <th scope="col" class="text-center">
                                          Delete
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(perm_attribute, $permIndex) in permissions_attributes"
                                          :key="$permIndex">
                                        <!-- NOTE: Datastructure = permissions[idcustomer][attribute], built dynamically -->
                                        <td v-if="perm_attribute !== 'User_permissions' " scope="row p-2"
                                            style="height: 38px; width: 135px;">
                                          {{ perm_attribute }}
                                        </td>
                                        <td v-else scope="row p-2" class="text-right"
                                            style="height: 38px;">
                                          permissions
                                        </td>
                                        <td class="text-center p-1">
                                          <b-form-checkbox
                                            v-if="permissions[student['idcustomer']]"
                                            v-model="permissions[ student['idcustomer'] ][perm_attribute + '_v']"
                                            class="m-0"
                                            :disabled=" isCurrentUser(student.idcustomer) "
                                            @change="updatePermissions( $event, perm_attribute + '_v', student )" />
                                        </td>
                                        <td
                                          v-if=" perm_attribute !== 'Reports' && !( perm_attribute === 'Users' && isAcademic ) && perm_attribute !== 'User_permissions' "
                                          class="text-center p-1">
                                          <b-form-checkbox
                                            v-if="permissions[student['idcustomer']]"
                                            v-model="permissions[ student['idcustomer'] ][perm_attribute + '_c']"
                                            class="m-0"
                                            :disabled="isCurrentUser(student.idcustomer)"
                                            @change="updatePermissions( $event, perm_attribute + '_c', student )" />
                                        </td>
                                        <td v-else />
                                        <td v-if="perm_attribute !== 'Reports'" class="text-center p-1">
                                          <b-form-checkbox
                                            v-if="permissions[student['idcustomer']]"
                                            v-model="permissions[ student['idcustomer'] ][perm_attribute + '_m']"
                                            class="m-0" :disabled=" isCurrentUser(student.idcustomer) "
                                            @change="updatePermissions( $event, perm_attribute + '_m', student )" />
                                        </td>
                                        <td v-if="perm_attribute !== 'Reports' && perm_attribute !== 'User_permissions'"
                                            class="text-center p-1">
                                          <b-form-checkbox
                                            v-if="permissions[student['idcustomer']]"
                                            v-model="permissions[ student['idcustomer'] ][perm_attribute + '_d']"
                                            class="m-0" :disabled=" isCurrentUser(student.idcustomer) "
                                            @change="updatePermissions( $event, perm_attribute + '_d', student )" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <sp-loader v-if="!permissions[student['idcustomer']]" />
                              </div>
                              <div>
                                <p>
                                  <span class="font-weight-bold">Reset Password:</span>
                                  <a href="#" class="pl-1" @click.prevent="passwordReset(student)">Send
                                    Password Reset
                                  </a>
                                </p>
                                <div v-if="passwordResetStatus[student.idcustomer]"
                                     class="border border-primary rounded text-center p-2">
                                  <small v-if=" passwordResetStatus[ student.idcustomer ] === 'success' ">
                                    A password reset email was sent to <strong>{{ student["email"] }}</strong>
                                    <br> If this email address is not active or if the email wasn't received, the
                                    following link can be copied into a browser to complete the password reset process:
                                    <br> {{ student.emailLink }}
                                  </small>
                                  <small v-if=" passwordResetStatus[ student.idcustomer ] === 'error' ">
                                    There was an error resetting the password. Please contact customer support.
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 text-right">
                              <b-button class="m-3" variant="outline-dark" @click="setUserPermissionsDisplay(student.idcustomer)">
                                <small>Close</small>
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                  </div>
                </div>
                <div v-else class="card text-center p-4">
                  <p>
                    There are {{ usersMultigroup.length }} {{ accountTypeText.memberType1 }} in this {{ accountTypeText.singular.toLowerCase() }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Add users to class modal -->
            <b-modal
              id="add-modal"
              v-model="modalShown"
              :title="' Add Existing ' + accountTypeText.memberType3 + ' to ' + accountTypeText.singular"
              cancel-variant="outline-secondary"
              @ok="addUserToOrganization(selectedAlias)">
              <div class="form-group">
                <label>{{ accountTypeText.memberType3 }}:</label>
                <select v-model="selectedAlias" class="form-control">
                  <option :value="null" disabled>
                    Please select a {{ accountTypeText.memberType3 }}
                  </option>
                  <option v-for="(seluser, key) in filterOrganizationUserByMg(organizationUsers)" :key="key" :value="seluser.aliasid">
                    {{ formatDisplayName(seluser) }} {{ seluser.multigroups.length ? "(in " + groupCount(seluser) + ")" : " (No " + accountTypeText.singular + ")" }}
                  </option>
                </select>
              </div>
              <assignment-email-checkbox message="Send assignment notification email" @sendEmail="selectedAliasOptions.sendEmail = !selectedAliasOptions.sendEmail" />
            </b-modal>

            <!-- Create/update user modal -->
            <classes-modal :user-info="userModalContent" @close="closeModal()">
              <div slot="header" style="width:100%;">
                <div class="row m-0">
                  <h3 v-if="userModalContent.email" class="col-11 m-0 p-0 text-capitalize black-text">
                    Update {{ accountTypeText.memberType1 }}
                  </h3>
                  <h3 v-else class="col-11 m-0 p-0 text-capitalize black-text">
                    Create New {{ accountTypeText.memberType3 }}
                  </h3>
                  <b-button-close class="col-1 p-0" text-variant="primary" @click="closeModal()">
                    <font-awesome-icon icon="times" size="sm" />
                  </b-button-close>
                </div>
              </div>
            </classes-modal>

            <!-- Remove/delete conformation modal -->
            <b-modal
              id="admin-conformation"
              v-model="showConfirmation"
              :title="showRemoveText ? 'Remove ' + accountTypeText.memberType3 : 'Delete ' + accountTypeText.memberType3 + ' From Account'"
              cancel-variant="outline-secondary"
              size="md"
              @ok="showRemoveText ? removeUser() : deleteUser()">
              <p v-if="showRemoveText" class="my-4">
                Warning: By proceeding this {{ accountTypeText.memberType3.toLowerCase() }} will be removed from the current {{
                  accountTypeText.singular.toLowerCase() }}. You can add them back or assign them to a different {{
                  accountTypeText.singular.toLowerCase() }} at any time.
              </p>
              <p v-if="showDeleteText" class="my-4">
                Warning: Are you sure you want to delete this {{ accountTypeText.memberType3.toLowerCase() }} from your account? You will not be able to re-add them.
              </p>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import axios from 'axios';
import ClassesModal from '@/components/Classes/ClassesModal.vue';
import AssignmentEmailCheckbox from '@/components/Elements/AssignmentEmailCheckbox.vue';
import _ from 'lodash';
import SpLoader from '@/components/SPLoader';

/**
 * NOTE! IMPORTANT!
 * This component relies on data ("user") populated by the Header
 * The API calls will fail until this data is loaded
 * The solution was to put a watcher on "user", and then init() after it populates
 */
export default {
  name: 'ClassesUsersAdmin',
  components: {
    'classes-modal': ClassesModal,
    'sp-loader': SpLoader,
    'assignment-email-checkbox': AssignmentEmailCheckbox
  },
  data() {
    return {
      // This is the ID of the individual sendgrid email that pertains to the Password Reset Process
      sendgridEmailID: 'ccedaab1-e7f8-4992-9f73-6fb9b23cbd79', //TODO: MOVE TO CONFIG
      organization_id: null,
      multigroup_id: null,
      loading: true,
      modalShown: false,
      showClassesForSmall: false,
      selectAllText: 'Select All',
      selectedAlias: null,
      selectedAliasOptions: { 'sendEmail': false },
      isModalVisible: false,
      userModalContent: {},
      student_attributes: [
        {key: 'first_name', value: 'First Name: '},
        {key: 'last_name', value: 'Last Name: '},
        {key: 'phone', value: 'Phone: '},
        {key: 'email', value: 'E-Mail: '},
        {key: 'username', value: 'User Name: '},
        {key: 'department', value: 'Department: '},
        {key: 'groupname', value: 'Group Name: '},
        {key: 'created_date', value: 'Created At: '},
        {key: 'last_access', value: 'Last Access: '},
      ],
      permissions_attributes: [
        'Assignments',
        'Documents',
        'Groups',
        'Users',
        'User_permissions',
        'Reports',
      ],
      checkboxesSelected: [],
      passwordResetStatus: {},
      showConfirmation: false,
      showRemoveText: false,
      showDeleteText: false,
      sortMethod: {
        type: 'last_name',
        'sortDirection': 'asc',
      },
      activeAccordions: [],
    };
  },
  computed: {
    userForm() {
      return this.$store.getters['users/getUsers'];
    },
    multigroups() {
      return this.$store.getters['multigroup/getMultigroups'];
    },
    aliasLimit() {
      return this.$store.getters['multigroup/getAliasLimit'];
    },
    aliasCount() {
      return this.$store.getters['multigroup/getAliasCount'];
    },
    showAllUsers () {
      return this.$store.getters['multigroup/getShowAllUsers'];
    },
    selectedMultigroup() {
      if (this.multigroups && this.multigroup_id) {
        for (let i = 0; i < this.multigroups.length; i++) {
          if (this.multigroups[i].id === this.multigroup_id) {
            return this.multigroups[i];
          }
        }
      }
      return {id: 'all', name: 'All members'};
    },
    usersMultigroup() {
      let usersPool ;
      if (this.showAllUsers) {
        usersPool = this.$store.getters['multigroup/getOrganizationUsers'];
      } else {
        usersPool = this.$store.getters['multigroup/getUsersMultigroups'];
      }
      return  _.orderBy(usersPool, [this.sortMethod['type']], this.sortMethod['sortDirection']);
    },
    organizationUsers() {
      return this.$store.getters['multigroup/getOrganizationUsers'];
    },
    permissions() {
      return this.$store.getters['multigroup/getMultigroupsPermissions'];
    },
    /**
     * Sets a boolean to only show SPE admin/ma AND SPA ma
     */
    showDeleteButton() {
      if (this.user) {
        if (this.isAcademic) {
          if (this.user.organization.role === 'master_admin') {
            return true;
          }
        } else if (this.isEnterprise) {
          if (
            this.user.organization.role === 'master_admin' ||
            this.user.organization.role === 'admin'
          ) {
            return true;
          }
        }
      }
      return false;
    },
    accountTypeText() {
      let accountType = {
        singular: '',
        plural: '',
        memberType1: '',
        memberType2: '',
        memberType3: '',
        admin: '',
      };
      if (this.user && _.has(this.user, 'organization.type')) {
        if (this.isAcademic) {
          accountType = {
            singular: 'Class',
            plural: 'Classes',
            memberType1: 'students',
            memberType2: 'Enrolled Students',
            memberType3: 'Student',
            admin: 'Instructor',
            organization: 'School',
            path: '/classes/',
          };
        } else {
          accountType = {
            singular: 'Group',
            plural: 'Groups',
            memberType1: 'members',
            memberType2: 'Group Members',
            memberType3: 'Group Member',
            admin: 'Admin',
            organization: 'Team',
            path: '/groups/',
          };
        }
      }
      return accountType;
    },
    maxUserSelected() {
      let maxNum = 0;
      if (this.organizationUsers) {
        if (this.user.organization.role === 'master_admin') {
          maxNum = this.usersMultigroup.length;
        } else {
          maxNum = this.usersMultigroup.length - 1;
        }
      }
      return maxNum;
    },
  },
  watch: {
    user: function(val, oldVal) {
      this.init();
    },
    userForm: function(val, oldVal) {
      this.processUserForm();
    },
    '$route.params.multigroup_id': function(val, oldVal) {
      this.init();
    },
  },
  created: function() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.multigroup_id === 'all') {
        this.setShowAllUsers(true);
        this.multigroup_id = null;
      } else {
        this.multigroup_id = parseInt(this.$route.params.multigroup_id);
        this.setShowAllUsers(false);
        this.loading = true;
      }
      if (this.user) {
        if (_.has(this.user, 'organization.id')) this.organization_id = this.user.organization.id;
        let promiseData = [];
        if (_.isEmpty(this.multigroups, true)) {
          promiseData.push(this.loadMultigroups());
        }
        promiseData.push(
          this.loadMultigroupUsers(),
          this.loadOrganizationUsers(),
        );
        Promise.all(promiseData).then(
          (response) => {
            this.loading = false;
          },
          (error) => {
            console.log(error);
            this.loading = false;
          },
        );
      }
    },
    /**
     * Is the given classID the id of the currently selected multigroup
     */
    isCurrentClass(classID) {
      return classID === this.multigroup_id;
    },
    /**
     * Checks or unchecks the user boxes
     */
    checkAll() {
      if (this.checkboxesSelected.length < this.maxUserSelected) {
        this.checkboxesSelected = [];
        this.selectAllText = 'Deselect All';
        this.usersMultigroup.forEach(multigroup => {
          // Prevent admin from removing itself from the group/class
          if (multigroup.idcustomer !== this.user.id) {
            this.checkboxesSelected.push(multigroup.aliasid);
          }
        });
      } else {
        this.checkboxesSelected = [];
        this.selectAllText = 'Select All';
      }
    },

    /**
     * Toggles permission select all. Since checkboxes are bound to a checkbox model, will iterate through and manually
     * rebind them on the global object.
     *
     * Calls function to send object of information to API
     */
    selectAllPermissions(userObj) {
      let isSelectAll = !this.calculateSelectAllValue(userObj.idcustomer);
      let newPermissions = {
        idcustomer: userObj.idcustomer,
        multigroupid: this.multigroup_id,
        permission: [],
      };

      for (let modPermission in this.permissions[userObj.idcustomer]) {
        if (modPermission !== 'loaded' && modPermission !== 'selectAll' && modPermission !== 'customer_id') {
          newPermissions['permission'].push(modPermission);
        }
      }

      let fieldsToToggle = newPermissions['permission'];
      let globalCustomerPermObj = this.permissions[userObj.idcustomer];
      for (let field in fieldsToToggle) {
        globalCustomerPermObj[fieldsToToggle[field]] = isSelectAll;
      }

      if (isSelectAll) {
        this.addMultigroupPermissions(newPermissions, userObj);
      } else {
        this.deleteMultigroupPermissions(newPermissions, userObj);
      }
    },
    updatePermissions(checked, permission, userObj) {
      let permissions = {
        idcustomer: userObj.idcustomer,
        multigroupid: this.multigroup_id,
        permission: [permission],
      };
      if (checked) {
        this.addMultigroupPermissions(permissions, userObj);
      } else {
        this.deleteMultigroupPermissions(permissions, userObj);
      }
    },
    addMultigroupPermissions(permissions, userObj) {
      this.$store.dispatch('multigroup/addMultigroupPermissions', permissions).then(
        (response) => {
          this.permissions[permissions.idcustomer]['loaded'] = true;
          // we need to update the number of perms of the customer so we can show Instructor vs Student or Admin vs user correctly
          this.updatePermissionsCountByIdc(userObj);
        },
        (error) => {
          console.log(error);
          this.permissions[permissions.idcustomer]['loaded'] = true;
        },
      );
    },
    deleteMultigroupPermissions(permissions, userObj) {
      this.$store.dispatch('multigroup/deleteMultigroupPermissions', permissions).then(
        (response) => {
          this.permissions[permissions.idcustomer]['loaded'] = true;
          // we need to update the number of perms of the customer so we can show Instructor vs Student or Admin vs user correctly
          this.updatePermissionsCountByIdc(userObj);
        },
        (error) => {
          console.log(error);
          this.permissions[permissions.idcustomer]['loaded'] = true;
        },
      );
    },
    /**
     * update multigroup_perms record in the mg-students-list for displaying Instructor/Student or Admin/User
     *
     * @param userObj
     */
    updatePermissionsCountByIdc(userObj) {
      let perms = this.permissions[userObj.idcustomer];
      let permCount = 0;
      let removeFromObj = ['customer_id', 'loaded', 'selectAll'];

      // get count of perms withou including anything from removeFromObj
      for (let key in perms) {
        if (perms[key] && !removeFromObj.includes(key)) {
          permCount++;
        }
      }

      // now update actual property for customer
      userObj.multigroup_perms = permCount;
    },
    /**
     * Format given datetime string to a human readable string from 'now'
     * IF the last access date is valid AND has happened after 2004
     *
     * @param date -- date from server in UTC
     * @return string -- time from now, expressed 'a year ago', '6 hours ago', etc
     * or 'Never' if the submitted date is from before 2004 (server default for no access is 1969)
     */
    formatDateTime(date) {
      let lastAccess = moment.utc(date).toDate();
      if (moment(lastAccess).isValid() && moment(lastAccess).year() > 2004) {
        return moment(lastAccess).fromNow();
      }
      return 'Never';
    },
    /**
     * Summons Moment
     */
    moment: function() {
      return moment();
    },
    /**
     * Wrapper to call approriate functions to sort main users table by 'type'
     *
     * if 'type' is different from the stored value, will re-order column by that value, descending
     * if 'type' is the same, will reverse the order
     *
     * @param type name of the usersMultigroup attribute you wish to sort by
     * i.e. 'type' = 'percent_complete' = usersMultigroup.percent_complete -- the table will be sorted by this field
     */
    sortColumn(type) {
      this.closeAllAccordions();
      if (this.sortMethod['type'] !== type) {
        this.sortMethod['sortDirection'] = 'asc';
      }
      if (this.sortMethod['type'] === type) {
        this.sortMethod['sortDirection'] = this.sortMethod['sortDirection'] === 'asc' ? 'desc' : 'asc';
      }
      this.sortMethod['type'] = type;
    },
    /**
     * The accordion dropdown is bound to activeAccordions[individualAccordion]
     * This will set every item to 'false', thus not showing them
     */
    closeAllAccordions() {
      for (let item in this.activeAccordions) {
        this.activeAccordions[item] = false;
      }
    },

    setUserPermissionsDisplay(localCustomerID){
      this.activeAccordions['accordion' + localCustomerID] = !this.activeAccordions['accordion' + localCustomerID];
    },

    /**
     * Wrapper. When switching between multigroups with the left panel controls, reset sorting to default
     */
    resetSorting() {
      this.closeAllAccordions();
      this.sortMethod['sortDirection'] = 'asc';
      this.sortMethod['type'] = 'last_name';
    },
    /**
     * Opens modal and passes content, if it exists
     */
    editDetails(student) {
      this.userModalContent = student;
      this.isModalVisible = true;
      this.$bvModal.show('classes-modal');
    },
    addExistingUser() {
      this.modalShown = !this.modalShown;
    },
    getMultigroupsPermissions() {
      return this.$store.getters['multigroup/getMultigroupsPermissions'];
    },
    /**
     * Closes the modal???
     */
    closeModal() {
      this.isModalVisible = false;
    },
    /**
     * Initiates password reset.
     */
    passwordReset(student) {
      let resetUrl;
      let customerData;
      if (this.showAllUsers) {
        resetUrl = this.SPENV.APP_API_URL + '/reset-password';
        customerData = {
          'email': student.email
        };
      } else {
        resetUrl = this.SPENV.APP_API_URL + '/admin-reset-password';
        customerData = {
          'multigroupID': this.multigroup_id,
          'users': [
            student
          ]
        };
      }
      axios({
        method: 'post',
        url: resetUrl,
        data: customerData,
        withCredentials: true,
      }).then(
        (response) => {
          // handle success
          Vue.set(this.passwordResetStatus, student.idcustomer, 'success');
          if (this.showAllUsers) {
            student['emailHash'] = response.data['emailkey'];
          } else {
            student['emailHash'] = response.data[student.idcustomer]['emailkey'];
          }
          student['emailLink'] = encodeURI(
            this.SPENV.APP_URL + '/iframes/reset-login.asp?ekey=1&em=' + student.email + '&k=' + student.emailHash,
          );
          // On success send email for password reset
          this.postToSendgrid(student);
        })
        .catch(
          (response) => {
            // handle error
            console.log(response);
            Vue.set(this.passwordResetStatus, student.idcustomer, 'error');
          });
    },
    /**
     * Sends out the reset password email. Called on a good response after reset information is logged in the DB
     */
    postToSendgrid(student) {
      let data = [
        {
          sendgridID: this.sendgridEmailID,
          recipient: student.email,
          params: {
            '%first_name%': student.first_name,
            '%link%': student.emailLink,
            '%email%': student.email,
          },
        },
      ];

      axios({
        method: 'post',
        url: this.SPENV.APP_API_URL + '/sendgrid/sendemail',
        data: data,
        withCredentials: true,
      }).catch(
        (response) => {
          // handle error
          console.log(response);
        });
    },
    /**
     * Prevents the accordion from expanding. Useful to use interface within accordion div
     */
    stopAccordion(event) {
      if (event) {
        event.stopPropagation();
      }
    },
    /**
     * Empty the selected users array
     */
    resetCheckedUsers() {
      this.checkboxesSelected = [];
      this.selectAllText = 'Select All';
    },
    /**
     * The SelectAll functionality is modeled after gmail. When this function is called, if any of the
     * possible fields are 'true' the function will return 'true'
     */
    calculateSelectAllValue(idcustomer) {
      for (let permission in this.permissions[idcustomer]) {
        if (permission !== 'loaded' && permission !== 'selectAll' && permission !== 'customer_id') {
          if (this.permissions[idcustomer][permission]) {
            return true;
          }
        }
      }
      return false;
    },
    /**
     * Loads all the multigroups for the current user
     */
    loadMultigroups() {
      this.resetCheckedUsers();
      return new Promise((resolve, reject) => {
        let multigroup = this.$store.getters['multigroup/getMultigroups'];
        // already loaded, don't load again
        if (multigroup && multigroup.rowid) {
          resolve();
        } else {
          this.$store.dispatch('multigroup/loadMultigroupData').then(
            (response) => {
              resolve();
            },
            (error) => {
              console.log(error);
              this.$router.push({name: 'classes'}).catch(err => {});
            },
          );
        }
      });
    },
    /**
     * Gets individual permissions for user
     */
    loadMultigroupPermissions(localCustomerID) {
      // Toggle user perms
      this.setUserPermissionsDisplay(localCustomerID);
      if (this.showAllUsers) {
        return;
      }
      this.$set(this.permissions, localCustomerID);
      return new Promise((resolve, reject) => {
        let multigroup = this.$store.getters['multigroup/getMultigroups'];
        // already loaded, don't load again
        if (multigroup && multigroup.rowid) {
          resolve();
        } else {
          this.$store.dispatch('multigroup/loadMultigroupPermissions', {
            multigroup_id: this.multigroup_id,
            customer_id: localCustomerID,
          }).then(response => {
            resolve();
          }, error => {
            console.log(error);
            this.$router.push({name: 'classes'}).catch(err => {});
          });
        }
      });

    },
    /**
     * Get all the users for a given multigroup
     */
    loadMultigroupUsers() {
      if (!this.showAllUsers) {
        return new Promise((resolve, reject) => {
          let multigroup = this.$store.getters['multigroup/getUserMultigroups'];
          // already loaded, don't load again
          if (multigroup && multigroup.rowid) {
            resolve();
          } else {
            this.$store.dispatch('multigroup/loadUsersMultigroup', this.multigroup_id).then(response => {
              this.resetSorting();
              resolve();
            }, error => {
              console.log(error);
              this.$router.push({name: 'classes'}).catch(err => {});
            });
          }
        });
      }
    },
    /**
     * Get all the users for the entire organization Jorge Cervantes 12/12/18
     */
    loadOrganizationUsers() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('multigroup/loadOrganizationUsers', this.organization_id).then(
          (response) => {
            this.resetSorting();
            resolve();
          }, (error) => {
            console.log(error);
            this.$router.push({name: 'classes'}).catch(err => {});
          },
        );
      });
    },
    /**
     * Add user to multigroup Jorge Cervantes 12/17/18
     */
    addUserToOrganization(aliasId) {
      this.loading = true;
      this.$store.dispatch('multigroup/addUserToOrganization', {
        organization_id: this.organization_id,
        alias_id: aliasId,
        send_email: this.selectedAliasOptions.sendEmail,
        multigroup_id: this.multigroup_id,
      }).then(
        (response) => {
          this.modalShown = false;
          this.loadMultigroupUsers();
          this.loadOrganizationUsers();
          this.selectedAlias = null;
          this.selectedAliasOptions.sendEmail = false;
          this.loading = false;
        });
    },
    /**
     * Processes both PATCH/POST user forms.
     */
    processUserForm() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let userFormData = this.$store.getters['users/getUsers'];
        let data = {
          multigroupID: this.multigroup_id ? this.multigroup_id : 'no-multigroup',
          organizationID: this.organization_id,
          type: userFormData.type,
          customers: [JSON.parse(JSON.stringify(userFormData))],
        };
        this.$store.dispatch('users/processUserData', data).then(
          (response) => {
            this.loadMultigroupUsers();
            this.loadOrganizationUsers();
            this.loading = false;
            resolve();
          },
          (error) => {
            console.log(error);
            this.$router.push({name: 'classes'}).catch(err => {});
          },
        );
      });
    },
    /**
     * Open documents tab for jorge Cervantes 12/10/18
     * @return {void}
     */
    openDocuments() {
      window.open(this.SPENV.APP_URL + '/weblms/group-docs.asp?popup=1', 'profileNGPopup', 'height=800, width=1033, directories=no, location=no, resizable=yes, menubar=no, toolbar=no, scrollbars=yes, status=no');
    },
    /**
     * Open user details tab for jorge Cervantes 12/11/18
     * @return {void}
     */
    openUserDetails(aliasid) {
      window.open(this.SPENV.APP_URL + '/weblms/group-userdetail.asp?aliasid=' + aliasid, 'profileNGPopup', 'height=800, width=1033, directories=no, location=no, resizable=yes, menubar=no, toolbar=no, scrollbars=yes, status=no');
    },
    /**
     * Formats progress display jorge cervantes 12/11/18
     * @param student obj
     * @return string (example) 45/100 (45%)
     */
    formatProgress(student) {
      let views = student.assignment_views ? student.assignment_views : 0;
      let lessons = student.number_of_lessons ? student.number_of_lessons : 0;
      let percent = views / lessons ? views / lessons : 0;
      return (views + '/' + lessons + ' (' + Math.round(percent * 100).toFixed(0) + '%)');
    },
    /**
     * Formats display name joining lname with name with comma or email, jorge cervantes 12/12/18
     * @param student obj
     * @returns string (example) Smith, John  (example) Smith  (example) John  (example) jsmith@email.com
     */
    formatDisplayName(student) {
      let display = [student.last_name, student.first_name]
        .filter(Boolean)
        .join(', ');
      return display || student.email;
    },
    /**
     * Makes a comma seperated list of groups/classes the member is in jcervantes 01/07/19
     * @param student obj
     * @return string
     */
    formatGroupColumn(student) {
      let groupColumn = 'No ' + this.accountTypeText.singular;
      if (student.multigroups && student.multigroups.length) {
        groupColumn = student.multigroups
          .map(e => e.multigroup_name)
          .join(', ');
      }
      return groupColumn;
    },
    /**
     * This will return the list of users that are not in the MG from the organizationsUsers jcervante 12/13/18
     * @return userslist
     */
    filterOrganizationUserByMg(users) {
      let usersNotInMg = [];
      for (let i = 0; i < users.length; i++) {
        let remove = false;
        if (users[i].multigroups.length) {
          // find if the user belongs to the current multigroup_id we are on
          remove = users[i].multigroups.find(
            m => m.multigroup_id === this.multigroup_id,
          );
        }
        if (!remove) {
          // if users is not in the mg_id push to the array for display
          usersNotInMg.push(users[i]);
        }
      }
      return _.orderBy(usersNotInMg, ['last_name', 'first_name'], 'asc');
    },
    /**
     * This will format the display for how many groups the user is in jcervantes 12/18/18
     * @return string
     */
    groupCount(user) {
      return (user.multigroups.length + ' ' + (user.multigroups.length > 1 ? this.accountTypeText.plural : this.accountTypeText.singular));
    },
    formatPatchData(multigroupId, aliasList) {
      let patchData = [];
      for (let i = 0; i < aliasList.length; i++) {
        patchData.push({
          op: 'remove',
          path: '/user',
          value: {aliasid: aliasList[i], multigroupid: multigroupId},
        });
      }
      return patchData;
    },
    showConfirmationModal(type) {
      this.showConfirmation = true;
      if (type === 'remove') {
        this.showRemoveText = true;
        this.showDeleteText = false;
      } else {
        this.showDeleteText = true;
        this.showRemoveText = false;
      }
    },
    removeUser() {
      this.loading = true;
      let patchData = this.formatPatchData(
        this.multigroup_id,
        this.checkboxesSelected,
      );
      this.$store.dispatch('multigroup/removeUserFromMultigroup', patchData).then(
        (response) => {
          this.checkboxesSelected = [];
          this.loadOrganizationUsers();
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        },
      );
    },
    deleteUser() {
      this.loading = true;
      this.$store.dispatch('multigroup/deleteMultigroupUser', this.checkboxesSelected).then(
        (response) => {
          this.checkboxesSelected = [];
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        },
      );
    },
    isCurrentUser(customerid) {
      let isCurrentUser = false;
      if (this.user.id === customerid) {
        isCurrentUser = true;
      }
      return isCurrentUser;
    },
    setShowAllUsers (value) {
      this.loading = true;
      this.$store.commit('multigroup/setShowAllUsers', value);
    }
  },
};
</script>



<style lang="scss">
.classes-block {
  font-size: .9rem;
  .header {
    font-weight: 600;
    font-size: .95rem;
  }
}
</style>

<template>
  <b-modal id="classes-modal">
    <form>
      <div class="row">
        <div class="col">
          <p class="red-text float-right small pl-3">
            *Required
          </p>
        </div>
      </div>
      <div v-for="attribute in userFields" :key="attribute.key" class="form-group row mb-2">
        <label :for="attribute.name" class="col-sm-4 col-form-label">{{ attribute.value }}</label>
        <div class="col-sm-8">
          <input
            :id="'form_input_' + attribute.key"
            :ref="attribute.key"
            v-model="formFields[attribute.key]"
            v-validate="rules[attribute.key]"
            :type="attribute.key"
            class="form-control"
            :name="attribute.name"
            :placeholder="attribute.demo"
            :disabled="formFields['type'] === 'PATCH' && (attribute.key === 'email')"
            :class="{'is-invalid': errors.has(attribute.name) || (emailError.status && attribute.key === 'email')}"
            @change="validateEmail(attribute.key)">
          <span class="small red-text">{{ errors.first(attribute.name) }}</span>
          <span v-if="emailError.status && attribute.key === 'email'" class="small red-text">{{ emailError.value }}</span>
        </div>
      </div>
      <AssignmentEmailCheckbox message="Send account creation email" @sendEmail="formFields['assignmentEmail'] = !formFields['assignmentEmail']" />
    </form>

    <div slot="modal-footer">
      <b-button
        variant="outline-secondary"
        class="m-2"
        @click="closeModal()">
        Cancel
      </b-button>
      <b-button
        variant="primary"
        class="m-2"
        :disabled="emailError.status || !formValid"
        @click="submitAndClose()">
        Submit
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import AssignmentEmailCheckbox from '@/components/Elements/AssignmentEmailCheckbox.vue';

export default {
  name: 'ClassesModal',
  components: {
    AssignmentEmailCheckbox
  },
  props: {
    'userInfo': {
      type: Object,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      formFields: {
        name: '',
        lastName: '',
        phone: '',
        email: '',
        password1: '',
        password2: '',
        department: '',
        assignmentEmail: false,
        type:null,
      },
      emailError: {
        status: false,
        value: null
      },
      // Rules for Vee-Validate. The 'confirmed' field forces it to match values with what is input
      rules: {
        name: {
          required: false,
          min: 2,
          max: 48
        },
        lastName: {
          required: false,
          min: 2,
          max: 48
        },
        phone: {
          required: false,
          max: 50
        },
        email: {
          required: true,
          email: true,
          min: 3,
          max: 256
        },
        password1: {
          required: true,
          min: 8,
          max: 24
        },
        password2: {
          required: true,
          confirmed: 'password1',
          min: 8,
          max: 24
        },
        department: {
          required: false,
          min: 2,
          max: 48
        }
      }
    };
  },
  computed: {
    /**
     * Used to dynamically generate the form fields in the template.
     * 'name' is a field required by vee-validate and must be unique
     */
    userFields() {
      let localUserFields = [
        {
          key: 'name',
          value: 'First Name: ',
          name: 'First Name',
          demo: 'First Name'
        },
        {
          key: 'lastName',
          value: 'Last Name: ',
          name: 'Last Name',
          demo: 'Last Name'
        },
        { key: 'phone', value: 'Phone: ', name: 'Phone', demo: 'Digits Only' },
        { key: 'email', value: 'E-Mail:* ', name: 'Email', demo: 'Required' },
        {
          key: 'department',
          value: 'Department: ',
          name: 'Department',
          demo: 'Department'
        }
      ];
      // If 'userInfo' is passed to the component, we can assume we will be doing a PATCH
      // If the data above is not present, assume it is a new user and add the password fields
      if (!Object.keys(this.userInfo).length) {
        localUserFields.push({
          key: 'password1',
          value: 'Password:* ',
          name: 'Password',
          demo: 'Password Required'
        });
        localUserFields.push({
          key: 'password2',
          value: 'Re-Type Password:* ',
          name: 'Re-Type Password',
          demo: 'Confirm Password Required'
        });
      }
      return localUserFields;
    },
    formValid() {
      return Object.keys(this.veeFields).every(key => this.veeFields[key].valid);
    }
  },
  watch: {
    userInfo: function (newUserInfo, oldUserInfo) {
      this.loadFormData(newUserInfo);
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('classes-modal');
    },
    /**
     * Load User data into form
     * We v-for this modal for every user on the page, but do not pass the data until well after creation.
     * By watching for the data change, we can reactively populate the form data
     *
     * @param {object} newUserInfo -- :prop passed from parent and 'watched' for change
     * @return {void} -- manipulates data object
     */
    loadFormData(newUserInfo) {
      this.formFields.name = newUserInfo['first_name'] || '';
      this.formFields.lastName = newUserInfo['last_name'] || '';
      this.formFields.phone = newUserInfo['phone'] || '';
      this.formFields.email = newUserInfo['email'] || '';
      this.formFields.password1 = '';
      this.formFields.password2 = '';
      this.formFields.department = newUserInfo['department'] || '';
      this.formFields.assignmentEmail = false;
      this.formFields.type = Object.keys(newUserInfo).length ? 'PATCH' : 'POST';
    },
    validateEmail(key) {
      if (key === 'email') {
        this.$store.dispatch('users/validateEmail', this.formFields[key]).then(
          (response) => {
            this.emailError.status = false;
            this.emailError.value = null;
          },
          (error) => {
            this.emailError.status = true;
            this.emailError.value = 'This email is in use. Please contact support';
            console.log(error);
          }
        );
      }
    },
    /**
     * Sets form data to the store so that it may be accessed by parent
     * Emits 'close' which will be observed by the parent
     */
    submitAndClose() {
      return new Promise((resolve, reject) => {
        // Validate all is a Veevalidate promise that returns a boolean
        this.$validator.validateAll().then(
          (response) => {
            if (response) {
              this.$store.dispatch('users/setUserData', this.formFields);
              this.closeModal();
              resolve();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      });
    }
  }
};
</script>
